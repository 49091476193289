import React from 'react'
import {LoginAction} from './../../redux/actions/index'
import {connect} from 'react-redux'
import {GoogleLogin} from 'react-google-login'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CLIENT_ID} from './../../utils/constants'

class Login extends React.Component{
    state = {
        isLoggedIn: false,
        accessToken: ''
    }

    onLogin = (res) => {
        if(res.accessToken){
            this.setState(state => ({
                isLoggedIn: true,
                accessToken: res.accessToken
            }))
            let authobj = {
                accessToken: res.accessToken,
                email: res.profileObj.email
            }
            this.props.login(authobj)
        }
        toast.success('Welcome!', this.props.alert)
        setTimeout(() => {
            this.props.history.push("/")
        }, 1000)
    }

    handleLoginFailure = (err) => {
        toast.error('Sorry. Failed to Login. Please try again', this.props.alert)
    }

    render(){
        return(
            <div>
                <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
                <div className="container" style={{paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%', marginTop: '10%'}} >
                    <div className="card mb-2 mt-1 pt-3" style={{paddingLeft: '2%', paddingBottom: '2%', paddingRight: '2%', backgroundColor: 'whitesmoke'}} >
                    <h1 className="display-5 text-center"> Funiwin Admin </h1>                    
                    <div className="text-center"> 
                        To continue, please sign in using your sam-media email account
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <GoogleLogin 
                            clientId={CLIENT_ID}
                            buttonText={'Sign In'}
                            onSuccess={this.onLogin}
                            onFailure={this.handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                            responseType='code,token,email'
                        />
                    </div>
    
                    </div>
            </div>
            </div>
        )
    }
}

const mapDispatchtoProps = dispatch => {
    return{
      login: (token) => {dispatch(LoginAction(token))}
    }
}

const mapStatetoProps = state => {
    return{
        loggedIn: state.loggedIn,
        alert: state.alert_options
    }
}
  
export default connect(mapStatetoProps, mapDispatchtoProps)(Login);