import axios from 'axios'
import {BASE_API_URL} from './../../utils/constants'

export const getQuestions = () => {
    return async (dispatch) => {
        axios.get(`${BASE_API_URL}/quiz`)
        .then((response) => {
            dispatch({
                type: 'GET_QUESTIONS',
                payload: response.data
            })
        })
        .catch((e) => console.log(e))
    }
}


export const getLanguages = () => {
    return async (dispatch) => {
        axios.get(`${BASE_API_URL}/util/getLangNames`)
        .then((response) => {
            dispatch({
                type: 'GET_LANGUAGES',
                payload: response.data
            })
        })
        .catch((e) => console.log(e))
    }
}


export const getDomain = () => {
    return async (dispatch) => {
        axios.get(`${BASE_API_URL}/util/getDomainNames`)
        .then((response) => {
            dispatch({
                type: 'GET_DOMAIN_NAMES',
                payload: response.data
            })
        })
        .catch((e) => console.log(e))
    }
}

export const SelectedContest = (data) => {
    return{
        type: 'GET_SELECTED_CONTEST',
        payload: data
    }
}

export const LoginAction = (token) => {
    return{
        type: 'LOGIN_USER',
        payload: token
    }
}

export const LogoutAction = () => {
    return{
        type: 'LOGOUT_USER',
    }
}
