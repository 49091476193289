import React from 'react';
import {Link} from 'react-router-dom';
import {GoogleLogout} from 'react-google-login'
import {connect} from 'react-redux'
import {CLIENT_ID} from './../../utils/constants'
import {LogoutAction} from './../../redux/actions/index'
import { toast, ToastContainer } from 'react-toastify';
class Home extends React.Component{
    componentDidMount(){
        if(this.props.loggedIn !== true){
            this.props.history.push("/login")
        }
    }
    onLogoutSuccess = () => {
        this.props.logout()
        this.props.history.push("/login")
    }
    onLogoutFailure = () => {
        toast.error('Failed to Logout', this.props.alert)
    }
    render(){
        return(
            <>
            <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
            <div className="container mt-4 mb-4">
                <h1 className="text-center" style={{display: 'block'}}>FUNIWIN ADMIN PANEL</h1>
                <h6 className="text-center">
                    Signed in as <span style={{fontWeight: 'bold'}}>{this.props.emailid}</span>
                </h6>
                <div className="text-center">
                    <GoogleLogout 
                        clientId={CLIENT_ID}
                        buttonText={'Sign Out'}
                        onLogoutSuccess={this.onLogoutSuccess}
                        onFailure={this.onLogoutFailure}
                    />
                </div>
                <hr />
                <div className="row mb-2">
                    <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: 'teal', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">Add a Contest</h5>
                            <p className="card-text">To create a new contest for different domains</p>
                            <Link to="/contest">
                            <button className="btn btn-warning font-weight-bold">Proceed <span><i className="arrow circle right icon"></i></span></button>
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">Translate Questions</h5>
                            <p className="card-text">To add translation for the questions in different languages</p>
                            <Link to="/translate">
                            <button className="btn btn-warning font-weight-bold">Proceed <span><i className="arrow circle right icon"></i></span> </button>
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: 'green', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">Active Contests</h5>
                            <p className="card-text">To view/edit/delete active contests in different domains</p>
                            <Link to="/view-contest">
                            <button className="btn btn-warning font-weight-bold">Proceed <span><i className="arrow circle right icon"></i></span> </button>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: 'maroon', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">LeaderBoard</h5>
                            <p className="card-text">To view leaderboard for contests in different domains</p>
                            <Link to="/leaderboard">
                            <button className="btn btn-warning font-weight-bold">Proceed <span><i className="arrow circle right icon"></i></span></button>
                            </Link>
                        </div>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: 'navy', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">New Domain</h5>
                            <p className="card-text">To add new domain and languages for that domain</p>
                            <Link to="/">
                            <button className="btn btn-secondary font-weight-bold">Coming Soon <span><i className="arrow circle right icon"></i></span></button>
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="card mb-2" style={{backgroundColor: '#35063e', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold text-uppercase">Site Contents</h5>
                            <p className="card-text">To translate site contents to a new language</p>
                            <Link to="/">
                            <button className="btn btn-secondary font-weight-bold">Coming Soon <span><i className="arrow circle right icon"></i></span></button>
                            </Link>
                        </div>
                        </div>
                    </div> */}
                </div>
            </div>
            </>
        )
    }
}

const mapDispatchtoProps = dispatch => {
    return{
      logout: () => {dispatch(LogoutAction())}
    }
}

const mapStatetoProps = state => {
    return{
        loggedIn: state.loggedIn,
        emailid: state.emailid,
        alert: state.alert_options
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);