import React from 'react'
import { Route, Redirect } from "react-router-dom";
import {connect} from 'react-redux'

const AuthRoutes = ({component: Component, loggedIn, ...rest}) => {
    if((sessionStorage.getItem('token') && sessionStorage.getItem('token').length > 0) || loggedIn === true){
        return <Route {...rest} component={Component} />
    }
    else{
        return <Redirect to="/login" />
    }
}

const mapStatetoProps = state => {
    return{
        loggedIn: state.loggedIn
    }
}

export default connect(mapStatetoProps, null)(AuthRoutes);