import React from 'react';
import Axios from 'axios';
import {Link} from 'react-router-dom';
import {Line} from 'rc-progress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-date-picker";
import {connect} from 'react-redux';
import Loader from '../../utils/Loader';
import {BASE_API_URL} from './../../utils/constants'

class AddContest extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            startDate: new Date(),
            endDate: new Date(Date.now() + (60*60*24*60*1000)),
            duration: 0,
            domain: '',
            level: 0,
            ques: 0,
            auto_renew: false,
            image_file: null,
            previewurl: '',
            imageurl: '',
            progress: 0,
            uploading: 0,
        }
        this.baseState = this.state
    }
    onNameChange = event => this.setState({name: event.target.value})
    onStartDateChange = date => this.setState({ startDate: date })
    onEndDateChange = date => this.setState({ endDate: date })
    onSelectChange = event => this.setState({[event.target.id] : event.target.value})
    onCheckboxChange = event => this.setState({auto_renew: event.target.checked})

    onFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if(file){
            if(file && file.size > 1500000){
                toast.error('Image Size should be less than 1.5 MB', this.props.alert)
            }
            else{
                reader.onloadend = () => {
                    this.uploadImage(reader.result)
                    this.setState({
                        image_file: file,
                        previewurl: reader.result,
                        imageurl: ''
                    })
                }
                reader.readAsDataURL(file)
            }    
        }
         
    }

    uploadImage = (file) => {        
        if(file){
            let base64Data = file
            Axios.post(`${BASE_API_URL}/contest/upload`, {data: base64Data, field_name: 'contest'}, {
            onUploadProgress: (ProgressEvent) => {
                this.setState({
                    uploading: true,
                    progress: Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total) 
                })
            }
            })
            .then((res) => {
                let url = new URL(res.data.url);
                url = url.pathname.split("/").filter((item, index) => index > 1).join("/")
                this.setState({
                    uploading: false,
                    imageurl: url
                })
                toast.success('Uploaded Successfully', this.props.alert)
            })
            .catch((e) => {
                console.log(e);
                toast.error('Image Upload Error', this.props.alert)
            })
        }
        else toast.error('Select a image first', this.props.alert)
    }

    onSubmitHandler = event => {
        let {name, duration, domain, level, ques, startDate, endDate, imageurl} = this.state
        event.preventDefault()
        if (name.length > 0 && duration > 0 && domain.length > 0 && level > 0 && ques > 0 && imageurl.length > 0){
            let difference = (endDate - startDate) / 1000;
            let durationinSeconds = duration * 30 * 24 * 60 * 60;
            if(difference > durationinSeconds) toast.warn(`${duration} months duration is too short`, this.props.alert)
            else if(endDate <= startDate) toast.warn('End date should be greater than start date', this.props.alert)
            else{
                let objtobeSent = {...this.state, previewurl: ''}
                Axios.post(`${BASE_API_URL}/contest/addContest`, objtobeSent)
                .then( (res) => {
                    (res.status) === 200 ? toast.success('Contest added to database', this.props.alert) : toast.error('All fields are required', this.props.alert);
                    setTimeout(() => {
                        this.setState((prevState) => {
                            return this.baseState
                        })
                    }, 1500)
                })
                .catch((e) => console.log(e));
            };
        }
        else{
            toast.error('All fields are required', this.props.alert)
        }
    }

    render(){
        let {previewurl, imageurl, uploading} = this.state
        
        if(imageurl){
            imageurl = 'https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/' + imageurl;
        }
        var imagePreview = null;        
        
        if (previewurl) {            
            if(imageurl) imagePreview = (<img src={imageurl} style={{objectFit: 'contain'}} className="card-img-top" width="100%" height="100%" alt="..." />);
            else imagePreview = (<img src={previewurl} style={{filter: 'blur(5px)', objectFit: 'contain'}} alt="..." className="card-img-top" width="100%" height="100%" />); 
        } 
        
        else imagePreview = (<img src={require("./../../assets/images/default-thumbnail.jpg")} alt="..." className="card-img-top" width="100%" height="100%" />);
        if(this.props.domainList.length === 0){
            return(
                <Loader />
            )
        }
        let {domainList} = this.props
        return(
        <div>
            <div>
            <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
            </div>
        
            <div className="container mt-4 mb-4" style={{paddingLeft: '10%', paddingRight: '10%'}} >
                <Link to="/">
                <button className="btn btn-secondary p-1 font-weight-bold"> <span><i className="home icon"></i></span>Home</button>
                </Link>
                <div className="card mb-2 mt-1" style={{paddingLeft: '2%', paddingRight: '2%', backgroundColor: 'whitesmoke'}} >
                <h1 className="display-4 text-center">Add Contest</h1>
                    
                <form action="/" method="POST" onSubmit={this.onSubmitHandler}>
                    <div className="form-row mb-2 mx-auto" style={{display: 'flex', justifyContent: 'center'}}>
                        <label htmlFor="upload-file">
                        <div className="card" style={{width: '15rem', height: '15rem', marginBottom: '3rem'}}>
                            {imagePreview}
                            {uploading
                            ?
                            (<div>
                                <Line percent={this.state.progress} strokeWidth="4" strokeColor="green" />
                            </div>)
                            :
                            null
                            }
                        </div>
                        </label>
                        <input type="file" className="form-control-file" id="upload-file" accept="image/jpg, image/png, image/jpeg, image/gif" hidden onChange={this.onFileChange} />
                    </div>          
                    <div className="form-group">
                        <label htmlFor="name">Prize Name</label>
                        <input type="text" className="form-control" id="name" placeholder="Enter name of the contest" onChange={this.onNameChange} value={this.state.name} />
                    </div>
                    
                    <div className="form-row mb-1">
                        <div className="col md-12 sm-12">
                        
                        <label htmlFor="duration">Duration (months) </label>
                            <select id="duration" className="form-control" value={this.state.duration !== 0 ? this.state.duration : "DEFAULT"} onChange={this.onSelectChange}>
                                <option disabled value="DEFAULT">Select duration</option>
                                <option>1</option><option>2</option><option>3</option>
                                <option>4</option><option>5</option><option>6</option>
                            </select>
                        
                        </div>
                    </div>
                    <div className="form-row mb-1">
                        <div className="col md-6 sm-12">
                        
                        <label htmlFor="start date">Start Date</label>
                        <br />
                        <DatePicker
                            onChange={this.onStartDateChange}
                            value={this.state.startDate}
                            className="form-control"
                        />                   
                        
                        </div>
                        
                        <div className="col md-4 sm-12">
                        <label htmlFor="end date">End Date</label>
                        <br />
                        <DatePicker
                            onChange={this.onEndDateChange}
                            value={this.state.endDate}
                            className="form-control"	
                        />
                        </div>
                    </div>

                    <div className="form-row mt-1">

                        <div className="form-group col-md-4 sm-12">
                        
                        <label htmlFor="domain">Domain</label>
                        <select id="domain" className="form-control" value={this.state.domain.length !== 0 ? this.state.domain : "DEFAULT"} onChange={this.onSelectChange}>
                        <option disabled value="DEFAULT">Select a domain</option>
                        {domainList.map(item => 
                                    <option key={domainList.indexOf(item)} value={item.name}>{item.name.toUpperCase()}</option>    
                        )}
                        </select>
                        
                        </div>

                        <div className="form-group col-md-4 sm-12">
                        
                        <label htmlFor="level">No. of Levels</label>
                        <select id="level" className="form-control" value={this.state.level !== 0 ? this.state.level : "DEFAULT"} onChange={this.onSelectChange}>
                            <option disabled value="DEFAULT">Select no. of levels</option>
                            <option>1</option><option>2</option><option>3</option>
                            <option>4</option><option>5</option>
                        </select>
                        
                        </div>

                        <div className="form-group col-md-4 sm-12">
                        
                        <label htmlFor="ques">No. of Questions (per level)</label>
                        <select id="ques" className="form-control" value={this.state.ques !== 0 ? this.state.ques : "DEFAULT"} onChange={this.onSelectChange}>
                            <option disabled value="DEFAULT">Select no. of questions</option>
                            <option>1</option><option>2</option>
                            <option>3</option><option>4</option><option>5</option>
                            <option>6</option><option>7</option><option>8</option>
                            <option>9</option><option>10</option>
                        </select>
                        </div>

                    </div>

                    <div className="form-check mb-2 mt-1">
                        <input className="form-check-input" type="checkbox" checked={this.state.auto_renew} id="auto-renew" onChange={this.onCheckboxChange}/>
                        <label className="form-check-label" htmlFor="auto-renew">
                            Auto Renew
                        </label>
                    </div>

                    <button className="btn btn-lg btn-info p-1 mb-2" style={{width: '100%'}}>Submit</button>

                </form>
                </div>
            </div>
        </div>
        )
    }
}

const mapStatetoProps = state => {
    return{
        domainList: state.domain,
        alert: state.alert_options
    }
}

export default connect(mapStatetoProps, null)(AddContest);